<template>
  <div class="earnings-list-container">
    <FloatInput :hideInput="true">
      <template #before-input>
        <b-button 
          variant="outline-primary"
          @click="exportCSV"
        >
          CSVダウンロード
        </b-button>
      </template>
    </FloatInput>
    <b-tabs>
      <b-tab active @click="activeTab = 1" title="売上推移">
        <Changes v-on:get-list-sales="getSalesList"/>
      </b-tab>
      <b-tab @click="activeTab = 2" title="請求明細">
        <BillingStatement v-on:get-list-Billing="getBillingList" />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { BTabs, BTab, BButton } from "bootstrap-vue";
import FloatInput from "@/@core/components/float-input/FloatInput.vue";
import Changes from "./Changes.vue";
import BillingStatement from "./BillingStatement.vue";
import { apiService } from "@/services/api.service";

export default {
  name: "EarningsList",
  components: {
    BTabs,
    BTab,
    FloatInput,
    BButton,
    Changes,
    BillingStatement,
  },
  data() {
    return {
      activeTab: 1,
      querySearch: {
        page: "1",
        size: 25,
        start_date: '',
        end_date: '',
      },
      querySearchBilling: {
        page: "1",
        size: 25,
      },
    };
  },
  computed: {
    queryRoute() {
      return this.$route.query;
    },
  },
  methods: {
    getSalesList(params) {
      this.querySearch = params;
    },
    getBillingList(params) {
      this.querySearchBilling = params;
    },
    async exportCSV() {
      try {
        this.startLoading()
        if(this.activeTab === 1) {
          var data = await apiService.get(
            `earnings/list/export?
            page=${this.querySearch.page}&
            size=${this.querySearch.size}&
            start_date=${this.querySearch.start_date}&
            end_date=${this.querySearch.end_date}`
          );
          this.endLoading()
          var BOM = "\uFEFF"
          data = BOM + data.data;
          const blob = new Blob([data], { type: 'text/csv' });
          const downloadLink = document.createElement('a');
          downloadLink.href = URL.createObjectURL(blob);
          const date = this.formatDate(new Date(), "「yyyy年MM月DD日」");
          downloadLink.download = `CHAIN_売上推移${date}`;
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        } else {
          var data = await apiService.get(
            `earnings/pay-detail/export-csv? 
            page=${this.querySearchBilling.page}&
            size=${this.querySearchBilling.size}`
          );
          this.endLoading()
          var BOM = "\uFEFF"
          data = BOM + data.data;
          const blob = new Blob([data], { type: 'text/csv' });
          const downloadLink = document.createElement('a');
          downloadLink.href = URL.createObjectURL(blob);
          const date = this.formatDate(new Date(), "「yyyy年MM月DD日」");
          downloadLink.download = `CHAIN_請求明細${date}`;
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        }
      } catch (error) {
        this.endLoading()
        this.notifyError(error.data.message)
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
