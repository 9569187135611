<template>
  <div class="earnings-changes-container">
    <!-- ! Card 1: Chart ---------------------------------------------------------------->
    <b-row>
      <b-col cols="12">
        <b-card class="card-chart">
          <div class="chart-stat">
            <div class="chart-stat-group">
              <span class="mr-15"
                >合計売上 <b>{{ formatNumber(this.earnings_chart.sum_sales) }}円</b></span
              >
              <span class="mr-15"
                >合計課金申込数 <b>{{ formatNumber(this.earnings_chart.sum_billing) }}人</b></span
              >
              <span class="mr-15"
                >合計解除数 <b>{{ formatNumber(this.earnings_chart.sum_cancel) }}人</b></span
              >
            </div>
            <div class="calendar-picker block">
              <feather-icon
                icon="CalendarIcon"
                size="25"
                class="flatpickr-icon"
              />
              <flat-pickr
                name="flatpickr"
                class="form-control calendar-picker-input"
                input-class="md-input"
                placeholder="表示期間"
                :config="{
                  mode: 'range',
                  dateFormat: 'Y/m/d',
                  locale: { rangeSeparator: ' ~ ' },
                  clickOpens: true,
                  minDate: minDate,
                  maxDate: maxDate
                }"
                v-model="dateRange"
                @input="handleSearch"
              >
              </flat-pickr>
              <b-button @click="searchListEarnings" variant="outline-primary" class="btn btn-primary btn-search">
                <feather-icon icon="SearchIcon" size="14"> </feather-icon>
              </b-button>
            </div>
          </div>
          <div class="separate-line"></div>
          <div class="chart">
            <vue-apex-charts
              ref="earningsChart"
              type="line"
              height="350"
              :options="earningsChartContainer.chartOptions"
              :series="earningsChartContainer.series"
            />
          </div>
        </b-card>
      </b-col>
    </b-row>

    <!-- ! Card 2: Stat ----------------------------------------------------------------->
    <b-row>
      <b-col cols="12">
        <div class="list-size mb-1" style="width: 160px">
          <v-select
            v-model="querySearch.size"
            :clearable="false"
            :options="options"
            @input="searchListEarnings"
          />
        </div>
        <b-table
          hover
          responsive
          :items="listSales"
          :fields="header"
          :sort-by.sync="sortBy"
          :filter="filter"
          :filter-included-fields="filterOn"
          show-empty
          tbody-tr-class="table-white-line"
        >
          <template v-for="item in header" v-slot:[`head(`+item.key+`)`]="data">
            <div
              :style="'width:' + data.field.width"
              class="header-table"
              :key="item.key"
            >
              <span class="text-center">{{ data.label }}</span>
            </div>
          </template>
          <template #cell(total_sales)="data">
            <span class="text-center" v-if="data.item.total_sales"> {{ formatPrice(data.item.total_sales)}}</span>
          </template>
          <template #cell(total_billing)="data">
            <span class="text-center" v-if="data.item.total_billing"> {{ data.item.total_billing }}人</span>
          </template>
          <template #cell(total_cancel)="data">
            <span class="text-center" v-if="data.item.total_cancel"> {{ data.item.total_cancel }}人</span>
          </template>
          <template #empty>
            <p class="text-center">データが見つかりません。</p>
          </template>
        </b-table>
        <div class="pagination float-right mb-3">
          <b-pagination
            v-model="pagination.currentPage"
            :total-rows="pagination.total"
            :per-page="pagination.perPage"
            @change="changePage"
          ></b-pagination>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BCard, BRow, BCol, BTable, BPagination, } from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import VueApexCharts from "vue-apexcharts";
import { RELOAD_TIME, STATUS } from "@/constant/constant";
import { apiService } from "@/services/api.service";
import moment from "moment";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  name: "EarningsChanges",
  components: {
    BCard,
    flatPickr,
    VueApexCharts,
    BRow,
    BCol,
    BTable,
    vSelect,
    BPagination,
  },
  data() {
    return {
      report_date: '',
      earningsChartContainer: {
        chartOptions: {
          height: 400,
          chart: {
            type: "line",
            toolbar: {
              show: false,
            },
            stacked: false,
          },
          dataLabels: {
            enabled: false,
          },
          series: [
            {
              name: "売上推移",
              type: "line",
              data: [],
            },
            {
              name: "課金申込数",
              type: "column",
              data: [],
            },
            {
              name: "解除数",
              type: "column",
              data: [],
            },
          ],
          stroke: {
            width: [3, 1, 1],
          },
          markers: {
            size: [4, 2],
            showNullDataPoints: false,
          },
          plotOptions: {
            bar: {
              columnWidth: "40%",
            },
          },
          legend: {
            show: true,
            position: "top",
            horizontalAlign: "left",
            fontSize: "12px",
            fontFamily: "Montserrat",
            offsetX: 40
          },
          xaxis: {
            categories: this.report_date,
          },
          yaxis: [

            {
              seriesName: "売上推移",
              axisTicks: {
                show: true,
              },
              axisBorder: {
                show: true,
                color: "#ff9500",
              },
              labels: {
                style: {
                  colors: "#ff9500",
                },
              },
              title: {
                text: "(円)",
                rotate: 0,
                style: {
                  color: "#ff9500",
                },
                offsetY: -150,
                offsetX: 33,
              },
            },
            {
              seriesName: "解除数",
              show: false,
              opposite: true,
            },
            {
              seriesName: "課金申込数",
              opposite: true,
              axisTicks: {
                show: true,
              },
              axisBorder: {
                show: true,
                color: "#1DC5D0",
                offsetX: -7,
              },
              labels: {
                style: {
                  colors: "#1DC5D0",
                },
              },
              title: {
                text: "(人)",
                rotate: 0,
                style: {
                  color: "#1DC5D0",
                },
                offsetY: -150,
                offsetX: -20,
              },
            },
          ],
          tooltip: {
            shared: false,
            intersect: true,
            x: {
                show: true,
                format: 'dd',
                formatter: undefined,
            },
            y: [{
              formatter: function (y) {
                if(typeof y !== "undefined") {
                  return  y.toFixed(0) + " 円";
                }
                return y;
              }
            }, {
              formatter: function (y) {
                if(typeof y !== "undefined") {
                  return  y.toFixed(0) + " 人";
                }
                return y;
              }
            }, {
              formatter: function (y) {
                if(typeof y !== "undefined") {
                  return  y.toFixed(0) + " 人";
                }
                return y;
              }
            }]
          },
          colors: ["#ff9500", "#377dff", "#1dc5d0"],
        },
      },
      header: [
        { label: "日付", key: "report_date", value: "", width: "150px" },
        { label: "売上合計", key: "total_sales", value: "", width: "150px" },
        {label: "課金申込数",key: "total_billing",value: "",width: "150px"},
        { label: "解約数", key: "total_cancel", value: "", width: "150px" },
      ],
      listSales: [],
      earnings_chart: '',
      maxDate: '',
      minDate: '',
      sortBy: "",
      deleteMessage: "",
      filter: null,
      filterOn: [],
      date: "",
      size: 20,
      list: [],
      pagination: {
        currentPage: 1,
        total: 0,
        perPage: 25,
      },
      querySearch: {
        page: 1,
        size: 25,
        start_date: '',
        end_date: '',
      },
      dateRange: "",
      options: [10, 25, 50],
    };
  },
  created() {
    this.handleSearch();
  },

  mounted() {
    this.getSalesList();
    this.onGetChartEarnings();
    this.reloadInfo = setInterval(() => {
    }, RELOAD_TIME);
  },
  beforeDestroy() {
    clearInterval(this.reloadInfo);
  },
  computed: {
    queryRoute() {
      return this.$route.query;
    },
  },
  watch: {
    querySearch() {
      console.warn(this.querySearch.start_date );
      if(this.querySearch.start_date !== this.querySearch.end_date ) {
          this.getSalesList();
      }
    },
  },
  methods: {
    handleSearch(e) {
      if (this.dateRange) {
        this.querySearch.start_date = this.dateRange.split(" ~ ")[0];
        this.querySearch.end_date = this.dateRange.split(" ~ ")[1];
        if (!this.querySearch.end_date) {
          this.querySearch.end_date = this.dateRange.split(" ~ ")[0];
        }
        this.minDate = moment(this.querySearch.start_date, "yyyy/MM/DD").add(-30, 'days').format('yyyy/MM/DD');
        this.maxDate = moment(this.querySearch.start_date, "yyyy/MM/DD").add(30, 'days').format('yyyy/MM/DD');
      } else {
        this.querySearch.start_date = moment().add(-30, 'days').format('yyyy/MM/DD');
        this.querySearch.end_date = moment().format('yyyy/MM/DD');
        this.minDate = '';
        this.maxDate = '';
      }
      this.querySearch.page = 1;
    },
    limitDate (){
      if(this.dateRange != '') {
        const limitDate = this.querySearch.start_date -30;
        const minDate = this.querySearch.start_date + 30;
        this.maxDate = limitDate;
        this.minDate = minDate;
      }
    },
    searchListEarnings() {
      this.querySearch.page = 1;
      this.onGetChartEarnings();
      this.getSalesList();
    },

    /* <!--@--> (changePage): Handle change pagination ------------------------------------------------------------------------- */
    changePage(e) {
      this.querySearch.page = e;
      this.getSalesList();
    },
    updateDataChart() {
      this.$refs.earningsChart.updateSeries([
          {
            name: "売上推移",
            type: "line",
            data: this.earnings_chart.total_sales,
          },
          {
            name: "課金申込数",
            type: "column",
            data: this.earnings_chart.total_billing,
          },
          {
            name: "解除数",
            type: "column",
            data: this.earnings_chart.total_cancel,
          },
      ]);
      this.$refs.earningsChart.updateOptions({
        xaxis:{          
          categories: this.report_date,
        }
      });
    },

 // get list Sales
    async getSalesList() {
      this.startLoading()
      try {
        let res = await apiService.get(
          `/earnings/list?
          page=${this.querySearch.page}&
          size=${this.querySearch.size}&
          start_date=${this.querySearch.start_date}&
          end_date=${this.querySearch.end_date}`
        );
        console.warn('getSalesList', this.querySearch);
        console.warn('res', res);
        if (res.data.code === STATUS.SUCCESS && res.data.data.earnings) {
          this.listSales = res.data.data.earnings;
          this.pagination.perPage = res.data.pagination.per_page;
          this.pagination.total = res.data.pagination.total;
        } else if (res.data.code === STATUS.NO_CONTENT) {
          this.list = [];
        } else {
          this.notifyError(res.data.message);
        }
        this.$emit('get-list-sales', this.querySearch);
      } catch (e) {
        this.notifyError(e)
      } finally {
        this.endLoading();
      }
    },
    /* <!--!--> Fetch: GET /earnings/chart (onGetChartEarnings) ------------------------------------------------------------------------- */
    async onGetChartEarnings() {
      this.startLoading();
      try {
        let res = await apiService.get(
          `/earnings/chart?
          page=${this.querySearch.page}&
          size=${this.querySearch.size}&
          start_date=${this.querySearch.start_date}&
          end_date=${this.querySearch.end_date}`
        );
        this.endLoading();
        this.earnings_chart = res.data.data;
        this.report_date = res.data.data.report_date;
        console.warn('onGetChartEarnings',res);
        this.updateDataChart();
      } catch (error) {
        this.endLoading();
        this.notifyError(error.data.message);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.mr-15 {
  margin-right: 15px;

  b {
    font-size: 16px;
  }
}
.earnings-changes-container {
  margin-top: 30px;

  .card-chart {
    .chart-stat {
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .calendar-picker {
        display: flex;
        position: relative;
        .calendar-picker-input {
          min-width: 220px;
          max-width: 220px; 
          padding-left: 40px;
          border-top-right-radius: 0px;
          border-bottom-right-radius: 0px;
        }
        .btn-search {
          border-top-left-radius: 0px;
          border-bottom-left-radius: 0px;
        }
        .flatpickr-icon {
          position: absolute;
          top: 6px;
          left: 9px;
        }
      }
    }
    .chart {
      margin-top: 10px;
    }
  }
}
</style>
