<template>
  <div class="earnings-billing-statement-container">
    <b-row>
      <b-col cols="12">
        <div class="list-size mb-1" style="width: 160px">
          <v-select
            v-model="querySearch.size"
            :clearable="false"
            :options="options"
            @input="searchQueryByOption"
          />
        </div>
        <b-table
          hover
          responsive
          :items="listPayDetail"
          :fields="header"
          :sort-by.sync="sortBy"
          :filter="filter"
          :filter-included-fields="filterOn"
          show-empty
          thead-tr-class="header-split"
          tbody-tr-class="table-white-line"
        >
          <template v-for="item in header" v-slot:[`head(`+item.key+`)`]="data">
            <div
              :style="'width:' + data.field.width"
              class="header-table"
              :key="item.key"
            >
              <span class="text-center">{{ data.label }}</span>
            </div>
          </template>
          <template #cell(unit_price)="data">
            <span class="text-center" v-if="data.item.unit_price"> {{ formatPrice(data.item.unit_price) }}</span>
          </template>
          <template #empty>
            <p class="text-center">データが見つかりません。</p>
          </template>
        </b-table>
        <div class="pagination float-right mb-3">
          <b-pagination
            v-model="pagination.currentPage"
            :total-rows="pagination.total"
            :per-page="pagination.perPage"
            @change="changePage"
          ></b-pagination>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol, BTable, BPagination, } from "bootstrap-vue";
import { apiService } from "@/services/api.service";
import { STATUS } from "@/constant/constant";
import vSelect from "vue-select";

export default {
  name: "EarningsBillingStatement",
  components: {
    BRow,
    BCol,
    BTable,
    BPagination,
    vSelect,
  },
  data() {
    return {
      header: [
        { label: "請求番号", key: "contract_code", value: "", width: "120px" }, //billing_number
        { label: "請求日", key: "expired_at", value: "", width: "130px" },
        { label: "商品ID", key: "product_id", value: "", width: "130px" },
        { label: "商品名", key: "product_name", value: "", width: "170px" },
        { label: "金額", key: "unit_price", value: "", width: "120px" },
        { label: "メールアドレス", key: "login_id", value: "", width: "170px" },
        { label: "ユーザー名", key: "user_name", value: "", width: "140px" },
        { label: "支払状況", key: "payment_status", value: "", width: "100px" },
      ],
      listPayDetail: [],
      totalRows: "1",
      sortBy: "",
      deleteMessage: "",
      filter: null,
      filterOn: [],
      pagination: {
        currentPage: 1,
        total: 0,
        perPage: 25,
      },
      querySearch: {
        page: 1,
        size: 25,
      },
      options: [10, 25, 50],
    };
  },
  created() {
    this.getPayDetailList();
  },
  mounted() {
    this.getPayDetailList();
  },
  computed: {
    queryRoute() {
      return this.$route.query;
    },
  },
  watch: {
    querySearch() {
      this.getPayDetailList();
    },
  },
  methods: {
    changePage(e) {
        this.querySearch.page = e;
        this.getPayDetailList();
    },
    searchQueryByOption(e) {
      this.querySearch.page = 1;
      this.getPayDetailList();
    },
    async getPayDetailList() {
      this.startLoading()
      try {
        let res = await apiService.get(
          `/earnings/pay-detail?
          page=${this.querySearch.page}&
          size=${this.querySearch.size}`
        );
        console.warn('querySearch', this.querySearch);
        console.warn('getPayDetailList', res);
        if (res.data.code === STATUS.SUCCESS && res.data) {
          this.listPayDetail = res.data.data.pay_detail;
          this.pagination.perPage = res.data.data.pagination.per_page;
          this.pagination.total = res.data.data.pagination.total;
        } else if (res.data.code === STATUS.NO_CONTENT) {
          this.listPayDetail = [];
        } else {
          this.notifyError(res.data.message);
        }
        this.$emit('get-list-Billing', this.querySearch);
      } catch (e) {
        this.notifyError(e)
      } finally {
        this.endLoading();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.earnings-billing-statement-container {
  margin-top: 30px;
}
</style>
